#dpHeader {
  min-height: 69px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  flex-shrink: 0;

  .logo-wrapper {
    width: 218px;
    display: flex;
    justify-content: flex-start;

    &.full-width {
      width: 1232px;
    }
  }

  #logo {
    opacity: 0;
    top: -70px;
    left: 10px;
    position: relative;
    width: 200px;
    height: auto;
    object-fit: contain;
  }

  .store-icon-wrapper {
    width: 1030px;
    display: flex;
    justify-content: flex-end;
  }
}

#dpHeader {
  background: none;
  padding-left: 0;
  padding-right: 10px;
  min-height: 70px;
}
#dpHeader .logo-wrapper {
  align-self: flex-start;
}
#dpHeader .logo-wrapper.full-width {
  width: 100%;
}
#dpHeader #logo {
  width: 80px;
  border-radius: 10px;
}

#mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 510px) {
  #dpHeader .logo-wrapper {
    padding-right: 0px;
  }
}

@media screen and (max-width: 1000px) {
  #dpHeader .store-icon-wrapper {
    padding-right: 0px;
  }
}