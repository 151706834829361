* {
  font-family: Arial, sans-serif;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.btn {
  margin-top: 27px;
  color: #ffffff;
  font-size: 21px;
  font-weight: 400;
  line-height: 71px;
  text-transform: uppercase;

  a.button {
    display: inline-block;
    width: 327px;
    height: 71px;
    background-color: #60b736;
    color: white;
    text-decoration: none;
    position: relative;
    transition: 0.25s ease-in-out;

    &.cta {
      overflow: hidden;

      > span {
        margin-left: 45px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        height: 71px;
        width: 60px;
        background-color: #72d443;
        opacity: 0.64;
      }

      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);

        #arrow {
          animation: bounce 0.6s;
          -webkit-animation: bounce 0.6s;
          -moz-animation: bounce 0.6s;
        }

        &:before {
          width: 100%;
          background: rgba(255, 255, 255, 0.15);
        }
      }
    }

    #arrow {
      position: relative;
      float: right;
      top: 26px;
      margin-right: 21px;
    }

    &:before,
    &:after,
    #arrow {
      transition: 0.25s ease-in-out;
    }
  }
}

.content-wrapper {
  width: 669px;
  margin: 0 auto;
  margin-top: 42px;

  .header {
    color: #171717;
    font-size: 36px;
    font-weight: 400;
    line-height: 42.53px;
    padding-left: 1px;
  }

  .text-content {
    color: #221f1f;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 80px;

    h2.bold {
      font-size: 16px;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .medium-bold {
      font-family: 'Lato Medium';
      font-weight: 500;
    }

    .bold {
      font-family: 'Lato - Bold';
      font-weight: 700;
    }

    a:not(.regular-link) {
      color: #221f1f;
      font-family: 'Lato Medium';
      text-decoration: underline;
    }
  }

  p.section-header {
    font-family: 'Lato - Bold';
    font-weight: 700;
    text-decoration: underline;
    margin-bottom: 16px;
  }

  ul {
    li span.item-header {
      text-decoration: underline;
    }

    &.decimal-listItem {
      list-style-type: decimal;

      li {
        padding-left: 10px;
      }
    }

    &.uses-of-information p:not(.text-underline) {
      margin-bottom: 16px;
    }
  }

  ol.roman-listItem {
    list-style-type: upper-roman;
  }
}

@-webkit-keyframes bounce {
  0% {
    right: 0;
  }

  25% {
    right: 4px;
  }

  50% {
    right: 10px;
  }

  100% {
    right: 0;
  }
}

.browserName,
.extensionName {
  text-transform: capitalize;
}

.hidden {
  display: none;
}

.uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: bold;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

@media only screen and (max-width: 700px) {
  .content-wrapper {
    width: 94%;
    margin-right: 3%;
    margin-left: 3%;
  }
}

body {
  background-image: url('images/background.jpg');
  overflow-y: auto;
}

.center-wrap {
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .left-content {
    max-width: 520px;
    padding-top: 98px;

    .title {
      color: #221f1f;
      font-size: 44px;
      font-weight: 700;
      padding: 0;
      margin: 0;
      line-height: 50px;
    }

    .subtitle {
      color: #221f1f;
      font-size: 28px;
      font-weight: 400;
      padding: 10px 0 15px;
      margin: 0;
      line-height: 34px;
    }

    .disclaimer_txt {
      color: #143463;
      font-size: 13px;
      font-weight: 400;
      margin-top: 45px;
      line-height: 16px;

      a {
        color: #4a5bff;
        text-decoration: none;
      }
    }
  }

  .right-content {
    margin-top: 49px;

    #pcImg {
      box-shadow: inset -1px 3px 8px 5px #1F87FF, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
}

.cardGrid {
  display: flex;
  justify-content: space-around;
  max-width: 1100px;
  margin: 67px auto 48px;

  .card {
    display: flex;
    flex-direction: column;
    width: 280px;
    opacity: 0;
    align-items: center;

    span {
      text-align: center;
      color: #143463;
      font-family: all-round-gothic;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .center-wrap .left-content {
    padding-top: 49px;
  }
}

* {
  font-family: Avant Garde HBL, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

body {
  background: url('images/background.jpg') no-repeat;
  background-size: cover;
}

.homepage {
  padding-top: 200px;
}

body {
  .center-wrap {
    .left-content {
      .subtitle {
        width: 470px;
      }

      .disclaimer_txt {
        width: 460px;
        font-size: 12px;
        margin-top: 33px;
        line-height: normal;
      }
    }
  }

  .cardGrid .card span {
    width: 230px;
  }

  .btn a.button {
    width: 285px;
  }
}

@media screen and (max-height: 650px) {
  .center-wrap {
    height: 435px;
  }
}